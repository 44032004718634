/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { add, eachDayOfInterval } from "date-fns";
import { alphaNumericSort } from "@/utils/helpers/alphaNumericSort";
import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Branch, Division, User } from "@/types/users/general";
import { Button } from "@/UI/Button";
import { classNames } from "@/utils/helpers/classNames";
import { ControlledDatePicker } from "@/UI/DatePicker";
import { ControlledGoogleAutocomplete } from "./CustomAutocomplete";
import { ControlledInput } from "@/UI/Input";
import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { ControlledTextArea } from "@/UI/TextArea";
import { CustomLocation } from "@/components/JobManagement/JobForm/CustomLocation";
import { DangerModal } from "@/UI/Modal";
import { FC, useEffect, useState } from "react";
import { Job } from "@/types/jobManagementService/general";
import { Location } from "@/types/jobManagementService/general";
import { MobileHeader } from "@/UI/Mobile";
import { toast } from "react-toastify";
import { useDeleteJob } from "@/lib/react-query/mutationHooks/useDeleteJob";
import { useEditJobDates } from "@/lib/react-query/mutationHooks/useEditJobDates";
import { useForm } from "react-hook-form";
import { useGQLCreateJob } from "@/lib/react-query/mutationHooks/useGQLCreateJob";
import { useGQLEditJob } from "@/lib/react-query/mutationHooks/useGQLEditJob";
import { useLocation, useNavigate } from "react-router-dom";
import { useMobileStoreSelectors } from "@/lib/zustand/mobileStore";
import { useSidebarStoreSelectors } from "@/lib/zustand/sidebarStore";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateJobFormSchema,
  DEFAULT_COLOR_OPTION,
  DEFAULT_VALUES,
  JobFormSchema,
  STATUS_OPTIONS,
  jobFormToMutateMapper,
  jobFormToQueryMapper,
} from "@/utils/formDefinitions/jobManagementService/general";
import {
  onStartDateChange,
  parseBranch,
  parseColor,
  parseDate,
  parseDivision,
  parseUser,
} from "@/utils/helpers/jobs";
import * as amplitude from "@amplitude/analytics-browser";
import { ControlledSearchableSelect } from "@/UI/SearchableSelect";
import { ControlledColorSelect } from "@/UI/Select";
import { ColorsQuery } from "@/lib/graphql/graphql";

type Props = {
  branches?: Branch[];
  colorsData?: ColorsQuery;
  divisions?: Division[];
  edit: boolean;
  job?: Job;
  users?: User[];
};

const JobForm: FC<Props> = ({
  branches,
  colorsData,
  divisions,
  edit,
  job,
  users,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [formLocation, setFormLocation] = useState<
    Location | string | undefined
  >("");
  const [openMap, setOpenMap] = useState(false);

  const { state } = useLocation();
  const isMobile = useMobileStoreSelectors.use.isMobile();
  const navigate = useNavigate();

  const setUnsavedDataWarning =
    useSidebarStoreSelectors.use.setUnsavedDataWarning();
  const setUnsavedDataWarningMessage =
    useSidebarStoreSelectors.use.setUnsavedDataWarningMessage();

  const { mutateAsync: mutateJobAsync, isPending: mutateJobLoading } =
    useGQLEditJob();
  const { mutateAsync: createJobAsync, isPending: createJobLoading } =
    useGQLCreateJob();
  const { mutateAsync: updateJobDatesAsync, isPending: updateJobDatesLoading } =
    useEditJobDates();
  const { mutateAsync: deleteJobAsync, isPending: deleteJobLoading } =
    useDeleteJob();

  const BRANCH_OPTIONS = (
    branches?.map((branch) => {
      return {
        id: branch.id,
        value: branch.name,
      };
    }) || []
  ).sort((a, b) => alphaNumericSort(a.value, b.value));

  const DIVISION_OPTIONS = (
    divisions?.map((division) => {
      return {
        id: division.id || "",
        value: division.divisionName || "",
      };
    }) || []
  ).sort((a, b) => alphaNumericSort(a.value, b.value));

  const USER_OPTIONS = (
    users?.map((user) => {
      return {
        id: user.id,
        value: `${user.firstName} ${user.lastName}`,
      };
    }) || []
  ).sort((a, b) => alphaNumericSort(a.value, b.value));

  const COLOR_OPTIONS =
    colorsData?.colors?.map((color) => {
      return {
        name: color?.name || "",
        code: color?.code || "",
        id: color?.id || "",
      };
    }) || [];

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<JobFormSchema>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...(state?.date && { startDate: state.date.toISOString() }),
      ...(state?.date && { endDate: state.date.toISOString() }),
      ...(state?.mapping && {
        customer: state.mapping.customerName,
        customerPhone: state.mapping.customerPhone?.replace(/\s+/g, ""),
        divisionId: parseDivision(state.mapping.divisionId, DIVISION_OPTIONS),
        branchId: parseBranch(state.mapping.branchId, BRANCH_OPTIONS),
        salesPerson: parseUser(USER_OPTIONS, state.mapping.userId),
      }),
    },
    ...(edit && {
      values: {
        location:
          job && job.address && job.address.lat
            ? {
                lat: job.address?.lat,
                lng: job.address?.lng,
                name: job.address?.name || job.address.formattedAddress || "",
              }
            : undefined,
        name: job?.name || "",
        number: job?.number || "",
        startDate: parseDate(job?.startDate),
        status: { id: job?.status || "", value: job?.status || "" },
        branchId: parseBranch(job?.branchId, BRANCH_OPTIONS),
        color: parseColor(job?.color?.id || "", COLOR_OPTIONS),
        customer: job?.customer?.name || "",
        customerContact: job?.customer?.contactName || "",
        customerEmail: job?.customer?.email || "",
        customerPhone: job?.customer?.phone || "",
        divisionId: parseDivision(job?.divisionId, DIVISION_OPTIONS),
        endDate: job?.endDate ? parseDate(job.endDate) : "",
        notes: job?.notes || "",
        projectOwner: parseUser(USER_OPTIONS, job?.projectOwner?.[0]),
        salesPerson: parseUser(USER_OPTIONS, job?.salesPerson?.[0]),
      },
    }),
    resetOptions: {
      keepValues: edit ? false : true,
    },
    resolver: zodResolver(CreateJobFormSchema),
  });

  const startDate = watch("startDate");
  const endDate = watch("endDate");

  const endDateMaxDate = startDate
    ? add(new Date(startDate), { days: 100 })
    : undefined;
  const endDateMinDate = startDate ? new Date(startDate) : undefined;

  const handleMobileEvents = () => {
    const hasColor = formState.dirtyFields.color;
    const hasNotes = formState.dirtyFields.notes;

    if (hasColor) {
      amplitude.track(AMPLITUDE_EVENTS.JOB_COLOR_UPDATED, {
        job_color: getValues("color")?.id || "",
        screen: "mobile_job_details",
      });
    }

    if (hasNotes) {
      amplitude.track(AMPLITUDE_EVENTS.JOB_NOTES_UPDATED, {
        screen: "mobile_job_details",
      });
    }

    amplitude.track(AMPLITUDE_EVENTS.JOB_EDITED, {
      job_name: getValues("name"),
    });
  };

  const handleUpdateJobDates = async (
    formData: JobFormSchema,
    jobId: string
  ) => {
    const updatedJobDates = {
      id: jobId,
      endDate: formData.endDate,
      startDate: formData.startDate,
    };

    await updateJobDatesAsync({ props: updatedJobDates });

    const interval = eachDayOfInterval({
      start: new Date(startDate),
      end: new Date(endDate),
    });

    amplitude.track(AMPLITUDE_EVENTS.JOB_DATE_UPDATED, {
      job_length: interval.length,
    });
  };

  const onSubmit = async (formData: JobFormSchema) => {
    if (edit && job) {
      const editData = jobFormToMutateMapper(formData, job.id);
      const hasStartDate = formState.dirtyFields.startDate;
      const hasEndDate = formState.dirtyFields.endDate;
      const editedFields = Object.keys(formState.dirtyFields);
      const response = await mutateJobAsync({ ...editData });

      if (hasStartDate || hasEndDate) {
        await handleUpdateJobDates(formData, job.id);
      }

      if (isMobile) {
        handleMobileEvents();
      } else {
        amplitude.track(AMPLITUDE_EVENTS.JOB_DETAILS_UPDATED, {
          edited_fields: editedFields,
        });
      }

      if (response.data.updateJob.success) {
        toast.success("Job edited.");

        setUnsavedDataWarning(false);

        reset(getValues());

        if (isMobile) {
          navigate(`/schedule`);
        }
      } else {
        toast.error("Error editing Job.");
      }
    } else {
      const createData = jobFormToQueryMapper(formData);
      const response = await createJobAsync({ ...createData });

      if (response.data.createJob.success) {
        setUnsavedDataWarning(false);

        toast.success("Job Created.");

        amplitude.track(AMPLITUDE_EVENTS.JOB_CREATED, {
          job_name: createData.name,
        });

        if (isMobile) {
          navigate(`/schedule`);
        } else {
          navigate(`/jobs/dashboard?jobId=${response.data.createJob.job?.id}`);
        }
      } else {
        toast.error("Error creating Job.");
      }
    }
  };

  const onDelete = async () => {
    if (job && edit) {
      try {
        await deleteJobAsync({ jobId: job.id });
        toast.success("Job deleted.");
        navigate("/schedule");
      } catch (error) {
        toast.error("Error Deleting Job.");
      }
    }
  };

  const onClickEndAdorment = () => {
    const currLocation = getValues("location");
    setFormLocation(currLocation);
    setOpenMap(true);
  };

  useEffect(() => {
    const div = document.getElementById("default-layout") as HTMLDivElement;
    div.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setUnsavedDataWarning(formState.isDirty);

    if (formState.isDirty) {
      setUnsavedDataWarningMessage(
        "You have unsaved changes to the Job Details. If you leave now, your updates will be lost."
      );
    } else {
      setUnsavedDataWarningMessage("");
    }

    return () => {
      setUnsavedDataWarning(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isDirty]);

  return (
    <section className="w-full px-2 min-[600px]:h-full min-[600px]:w-[916px]">
      {isMobile ? (
        <MobileHeader headerText={edit ? "Edit Job Details" : "Create Job"} />
      ) : (
        <h1 className="mb-6 mt-24 text-4xl font-semibold">
          {edit ? "Edit Job Details" : "Create Job"}
        </h1>
      )}

      <form
        autoComplete="off"
        className="flex flex-col gap-y-5 min-[600px]:grid min-[600px]:grid-cols-2 min-[600px]:gap-4 min-[600px]:px-1"
        onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}
        onKeyDown={(ev) => {
          if (ev.key === "Enter") ev.preventDefault();
        }}
      >
        <div className="flex flex-col gap-y-5 min-[600px]:grid min-[600px]:grid-cols-2 min-[600px]:grid-rows-5 min-[600px]:gap-4">
          <ControlledInput
            containerClasses="col-span-2"
            control={control}
            label="Job Name*"
            name="name"
          />

          <div className="flex flex-col gap-y-5 min-[600px]:col-span-2 min-[600px]:flex-row min-[600px]:gap-x-5">
            <ControlledGoogleAutocomplete
              control={control}
              name="location"
              {...(!isMobile && { onClickEndAdorment: onClickEndAdorment })}
            />

            <ControlledColorSelect
              control={control}
              label="Color"
              name="color"
              options={
                COLOR_OPTIONS.length ? COLOR_OPTIONS : [DEFAULT_COLOR_OPTION]
              }
            />
          </div>

          {!isMobile && (
            <ControlledSelect
              control={control}
              label="Salesperson"
              name="salesPerson"
              options={USER_OPTIONS}
            />
          )}

          {!isMobile && (
            <ControlledSelect
              control={control}
              label="Project Owner"
              name="projectOwner"
              options={USER_OPTIONS}
            />
          )}

          <ControlledInput control={control} name="customer" label="Customer" />

          <ControlledInput
            control={control}
            label="Customer Contact"
            name="customerContact"
          />

          <ControlledInput
            control={control}
            label="Customer Email"
            name="customerEmail"
          />

          <ControlledInput
            control={control}
            label="Customer Phone"
            name="customerPhone"
          />
        </div>

        <div className="flex flex-col gap-y-5 min-[600px]:grid min-[600px]:grid-cols-2 min-[600px]:grid-rows-5 min-[600px]:gap-4">
          <ControlledInput control={control} name="number" label="Job Number" />

          {!isMobile && (
            <ControlledSelect
              control={control}
              name="status"
              options={STATUS_OPTIONS}
              label="Job Status"
            />
          )}

          <ControlledDatePicker
            control={control}
            externalChangeHandler={(startDate) =>
              onStartDateChange(startDate, endDate, setValue)
            }
            label="Start Date*"
            name="startDate"
            parseValue
          />

          <ControlledDatePicker
            control={control}
            disabled={!startDate}
            label="End Date*"
            maxDate={endDateMaxDate}
            minDate={endDateMinDate}
            name="endDate"
            parseValue
          />

          <ControlledSearchableSelect
            control={control}
            label="Division*"
            name="divisionId"
            options={DIVISION_OPTIONS}
            readonly={isMobile}
            selectorContainerClasses="!top-[4rem]"
          />

          <ControlledSearchableSelect
            control={control}
            label="Branch"
            name="branchId"
            options={BRANCH_OPTIONS}
            readonly={isMobile}
            selectorContainerClasses="!top-[4rem]"
          />

          <ControlledTextArea
            control={control}
            name="notes"
            containerClassNames="col-span-2 row-span-2"
            textareaStyles={`!resize-none ${
              isMobile ? "!h-[144px]" : "!h-full"
            }`}
            label="Notes"
          />
        </div>

        <div
          className={classNames(
            "hidden min-[600px]:flex",
            edit ? "col-span-2 justify-between" : "col-start-2 justify-end"
          )}
        >
          <Button
            className={classNames("rounded-md", edit ? "block" : "hidden")}
            disabled={
              mutateJobLoading ||
              createJobLoading ||
              updateJobDatesLoading ||
              deleteJobLoading
            }
            label="Delete Job"
            type="button"
            variant="secondary"
            onClick={() => setDeleteModalOpen(true)}
          />

          <div className="flex gap-x-3">
            {!edit && (
              <Button
                className="rounded-md"
                disabled={
                  mutateJobLoading ||
                  createJobLoading ||
                  updateJobDatesLoading ||
                  deleteJobLoading ||
                  formState.isDirty
                }
                label="Cancel"
                type="button"
                variant="secondary"
                onClick={() => {
                  if (edit) {
                    navigate(`/jobs/dashboard?jobId=${job?.id}`);
                  } else {
                    navigate("/schedule");
                  }
                }}
              />
            )}

            <Button
              disabled={
                mutateJobLoading ||
                createJobLoading ||
                updateJobDatesLoading ||
                deleteJobLoading ||
                !formState.isDirty
              }
              label={edit ? "Update" : "Create Job"}
              variant="primary"
              className="rounded-md"
              type="submit"
            />
          </div>
        </div>

        {isMobile && (
          <>
            <Button
              className="rounded-md"
              label="Cancel"
              type="button"
              variant="secondary"
              onClick={() => navigate("/schedule")}
            />

            <Button
              label={edit ? "Save Updates" : "Create Job"}
              variant="primary"
              className="rounded-md"
              type="submit"
              disabled={!formState.isDirty}
            />
          </>
        )}
      </form>

      {openMap && !isMobile && (
        <CustomLocation
          open={openMap}
          jobFormInputLocation={formLocation}
          setOpen={setOpenMap}
          setValue={setValue}
        />
      )}

      {!isMobile && (
        <DangerModal
          cancelAction={() => setDeleteModalOpen(false)}
          cancelButtonDisabled={deleteJobLoading}
          confirmAction={onDelete}
          confirmButtonDisabled={deleteJobLoading}
          message="Are you sure you want to delete this Job? This action cannot be undone."
          open={deleteModalOpen}
          title="Delete Job"
        />
      )}
    </section>
  );
};

export { JobForm };
