import { addDays } from "date-fns";
import { create } from "zustand";
import { createSelectors } from "./createSelectors";

type MobileStore = {
  isMobile: boolean;
  jobQueryEndDate: string;
  jobQueryStartDate: string;
  scrollIntoViewItemId: string;
  setIsMobile: (isMobile: boolean) => void;
  setJobQueryEndDate: (endDate: string) => void;
  setJobQueryStartDate: (startDate: string) => void;
  setScrollIntoViewItemId: (item: string) => void;
};

export const useMobileStore = create<MobileStore>((set) => ({
  isMobile: false,
  jobQueryEndDate: addDays(new Date(), 14).toISOString(),
  jobQueryStartDate: new Date().toISOString(),
  scrollIntoViewItemId: "",
  setIsMobile: (isMobile) => set({ isMobile }),
  setJobQueryEndDate: (endDate) => set({ jobQueryEndDate: endDate }),
  setJobQueryStartDate: (startDate) => set({ jobQueryStartDate: startDate }),
  setScrollIntoViewItemId: (item) => set({ scrollIntoViewItemId: item }),
}));

export const useMobileStoreSelectors = createSelectors(useMobileStore);
