/* eslint-disable @typescript-eslint/no-explicit-any */
import { CameraIcon, FilledSquaresIcon } from "@/assets";
import { Attachment } from "@/types/assetInventory/assets/general";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AssetImageModal } from "./AssetImageModal";
import { useAuthContext } from "@/components/Auth/AuthWrapper";

interface AssetOverviewImagesProps {
  images: Attachment[];
}
export const AssetOverviewImages: FC<AssetOverviewImagesProps> = ({
  images,
}) => {
  const navigate = useNavigate();
  const { hasAssetCrud, hasRibbiotAdmin } = useAuthContext();
  const searchParams = new URLSearchParams(location.search);
  const assetId = searchParams.get("assetId") ?? "";
  const [imageModal, setImageModal] = useState<{
    open: boolean;
    initialIndex?: number;
  }>({ open: false, initialIndex: 0 });

  if (images.length < 1) {
    return (
      <div className="flex w-full flex-col items-center justify-center space-y-2">
        <CameraIcon width="100" height="50" fill="#9b9b9b" />
        <p className="text-lg font-semibold text-[#9b9b9b]">No Asset Images</p>
        {(hasAssetCrud || hasRibbiotAdmin) && (
          <div>
            <p className="text-base  text-[#9b9b9b]">To upload Asset images</p>
            <button
              className="w-full text-base text-[#9b9b9b]  underline"
              onClick={() =>
                navigate(`/assets/asset-images?assetId=${assetId}`)
              }
            >
              edit Asset Images
            </button>
          </div>
        )}
      </div>
    );
  }

  if (images.length === 1) {
    return (
      <div
        className="mx-auto  h-full   w-full overflow-hidden rounded-xl bg-cover object-center"
        style={{ backgroundImage: `url(${images[0].url})` }}
      />
    );
  }

  if (images.length === 2) {
    return (
      <div className="flex divide-x-4 overflow-hidden rounded-xl">
        <img
          src={images[0].url}
          alt={images[0].name}
          className="w-[75%] object-cover"
        />
        <img
          className="w-[25%] object-cover"
          src={images[1].url}
          alt={images[1].name}
        />
      </div>
    );
  }

  return (
    <div className="flex w-full divide-x-4 overflow-hidden rounded-xl">
      <div className="basis 2/3 w-full">
        <img
          src={images[0].url}
          alt={images[0].name}
          className="h-full w-full object-cover"
        />
      </div>
      <div className="relative flex w-full basis-1/3 flex-col ">
        <img
          className="h-[50%] border-b-4 object-cover"
          src={images[1].url}
          alt={images[1].name}
        />

        <img
          className="h-[50%] object-cover"
          src={images[2].url}
          alt={images[2].name}
        />
        <div className="absolute bottom-3 left-0 right-0  flex text-center text-white">
          <button
            onClick={() => setImageModal({ open: true })}
            className=" mx-auto flex items-center justify-center space-x-2 rounded-full bg-black bg-opacity-50 px-4 py-2"
          >
            <FilledSquaresIcon />
            <span className="text-sm">View All ({images.length})</span>
          </button>
        </div>
      </div>
      <AssetImageModal
        imageModal={imageModal}
        setImageModal={setImageModal}
        images={images as any}
      />
    </div>
  );
};
